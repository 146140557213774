:root {
  --light-blue: #0d9e8e;
}

body,
#app {
  background: #00353f;
  background: -moz-radial-gradient(center,
      ellipse cover,
      #00353f 0%,
      #001a1f 100%);
  background: -webkit-radial-gradient(center,
      ellipse cover,
      #00353f 0%,
      #001a1f 100%);
  background: radial-gradient(ellipse at center, #003e4a 0%, #001a1f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00353f', endColorstr='#001a1f', GradientType=1);
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin: 0px;
  font-family: "Montserrat", sans-serif;
  color: var(--light-blue);
}

.action-buttons {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  position: relative;
  z-index: 2;
}

.action-buttons button {
  background: #001c20;
  padding: 14px 2px;
  color: #098275;
  border: 0px;
  font-size: 16px;
  font-weight: 400;
  border-right: 1px solid #00353d;
  font-family: "Montserrat", sans-serif;
  margin: 0px;
}

.action-buttons button:focus {
  outline: none;
}

.action-buttons .toggle-action {
  background: #002930;
  font-size: 26px;
  padding: 9px 2px;
  color: var(--light-blue);
}

.description-wrap {
  padding: 20px 0px 5px;
  text-align: center;
  color: #fff;
}

.calculated-values {
  display: inline-block;
  vertical-align: middle;
  font-size: 40px;
  font-weight: 100;
}

.block-label {
  font-size: 15px;
  font-weight: 700;
  opacity: 0.4;
}

.predicted-block-wrap .calculated-values {
  font-weight: 500;
  color: #0e9e8e;
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 14px 0 12px;
  width: 100%;
  padding: 0px 10px;
  background: #0e9e8e;
  box-sizing: border-box;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--light-blue);
  border-radius: 0px;
  border: 0px;
}

input[type="range"]::-webkit-slider-thumb {
  border: 0px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #002930;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -16.5px;
  border: 2px solid var(--light-blue);
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--light-blue);
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--light-blue);
  border-radius: 0px;
  border: 0px;
}

input[type="range"]::-moz-range-thumb {
  border: 2px solid var(--light-blue);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #002930;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--light-blue);
  border-radius: 0px;
  border: 0px;
}

input[type="range"]::-ms-fill-lower {
  background: var(--light-blue);
  border: 0px;
  border-radius: 0px;
}

input[type="range"]::-ms-fill-upper {
  background: var(--light-blue);
  border: 0px;
  border-radius: 0px;
}

input[type="range"]::-ms-thumb {
  border: 2px solid var(--light-blue);
  height: 50px;
  width: 35px;
  border-radius: 10px;
  background: var(--light-blue);
  cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower {
  background: var(--light-blue);
}

input[type="range"]:focus::-ms-fill-upper {
  background: var(--light-blue);
}

.range-input-wrap {
  padding: 15px 0px 10px;
  text-align: center;
}

.range-input-label {
  margin-bottom: 10px;
  font-weight: 700;
  opacity: 0.4;
}

.block-wrap {
  padding-bottom: 20px;
}

.predicted-block-wrap {
  background: rgba(0, 0, 0, 0.15);
}

.percentage-block-wrap {
  background: rgba(0, 0, 0, 0.4);
  padding: 20px 0px 25px;
}

footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 14px 22px;
  font-size: 14px;
}

footer .open-modal,
footer a {
  background: transparent;
  border: 0px;
  padding: 0px;
  color: var(--light-blue);
  font-weight: 700;
  font-size: 14px;
}

footer .right {
  text-align: right;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1001;
  display: none;
  color: #fff;
}

.modal.open {
  display: block;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 90%;
  max-height: 90%;
  display: flex;
}

.modal-box summary {
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  background: #00353f;
  background: -moz-radial-gradient(center,
      ellipse cover,
      #00353f 0%,
      #001a1f 100%);
  background: -webkit-radial-gradient(center,
      ellipse cover,
      #00353f 0%,
      #001a1f 100%);
  background: radial-gradient(ellipse at center, #003e4a 0%, #001a1f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00353f', endColorstr='#001a1f', GradientType=1);
  border: 2px solid #0e9e8e;
}

.close-modal {
  background-color: #001c20;
  border-radius: 50%;
  border: 0px;
  padding: 0px;
  height: 40px;
  width: 40px;
  position: absolute;
  right: -20px;
  top: -20px;
  line-height: 1.3rem;
}

.close-modal img {
  display: block;
  width: 100%;
  height: auto;
}

a {
  color: var(--light-blue);
  font-weight: 500;
}

.table-wrap {
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  position: relative;
  padding: 0;
}

.rpe-table {
  margin: 0;
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  margin-left: 33px;
}

.rpe-table thead {
  background-color: #0d3e46;
  color: var(--light-blue);
}

.rpe-table tbody tr td:first-of-type {
  background-color: #0d3e46;
  font-weight: bold;
  color: var(--light-blue);
}

.rpe-table th,
.rpe-table td {
  padding: 3px 4px;
  text-align: center;
  border: 1px solid;
}

.rpe-table thead th:first-of-type {
  border-bottom: 0px;
}

.rpe-table th:first-of-type,
.rpe-table td:first-of-type {
  position: absolute;
  width: 24px;
  left: 0;
  top: auto;
  min-height: 16px;
}

.modal h1 {
  font-size: 22px;
  margin: 30px 0px 18px;
  color: var(--light-blue);
}

.modal h1:first-of-type {
  margin-top: 10px;
}

.modal a {
  color: var(--light-blue);
}